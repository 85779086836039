var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sidebar bg-blue"
  }, [_c('div', {
    staticClass: "pt-5"
  }, [_c('div', {
    staticClass: "flex px-5 mb-9 h-8 items-center justify-between"
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "duration": 100
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/logos/Logo-White.png")
    }
  })]), _c('span', {
    staticClass: "cursor-pointer inline-block -mr-2",
    on: {
      "click": function ($event) {
        return _vm.toggleMenu();
      }
    }
  }, [_c('Close', {
    attrs: {
      "width": "40",
      "height": "40"
    }
  })], 1)], 1), _c('router-link', {
    staticClass: "menu flex items-center justify-between gap-4 px-5 mb-10 bg-blue-700 p-2",
    attrs: {
      "to": "/profile"
    }
  }, [_c('div', {
    staticClass: "flex gap-4 items-center"
  }, [_c('User', {
    attrs: {
      "width": "24",
      "height": "24"
    }
  }), _c('div', {
    staticClass: "text-left"
  }, [_c('p', {
    staticClass: "text-white text-base font-bold leading-tight"
  }, [_vm._v(_vm._s(_vm.user.name))]), _c('p', {
    staticClass: "text-xs text-white"
  }, [_vm._v(_vm._s(_vm.user.group))])])], 1), _c('div', {
    staticClass: "relative text-left user-item"
  }, [_c('span', {
    staticClass: "inline-block absolute top-1/2 leading-0 transform -translate-y-1/2 right-0 cursor-pointer",
    on: {
      "click": function ($event) {
        return _vm.toggleUser();
      }
    }
  }, [_c('Chevron', {
    attrs: {
      "width": "16",
      "height": "16",
      "direction": "right",
      "color": "#ffffff"
    }
  })], 1)])]), _vm.expandUser ? _c('div', {
    staticClass: "relative"
  }, [_c('div', {}, [_c('div', {
    staticClass: "cursor-pointer text-yellow text-sm",
    on: {
      "click": function ($event) {
        return _vm.doLogout();
      }
    }
  }, [_vm._v("Logout")])])]) : _vm._e(), _vm._l(_vm.menuList, function (menu, index) {
    return _c('div', {
      key: index
    }, [!menu.submenu ? _c('router-link', {
      staticClass: "flex px-5 py-3 gap-4 items-center transition-all ease-linear duration-200 hover:text-primary-500",
      class: {
        'active-link': _vm.activePath === menu.url
      },
      attrs: {
        "to": menu.url
      }
    }, [_c('span', {
      staticClass: "inline-block flex-shrink-0"
    }, [_c(menu.icon, {
      tag: "component",
      attrs: {
        "width": "24",
        "height": "24"
      }
    })], 1), _c('transition', {
      attrs: {
        "name": "fade",
        "duration": 100
      }
    }, [_c('div', {
      staticClass: "font-medium title whitespace-nowrap"
    }, [_vm._v(_vm._s(menu.title))])])], 1) : _c('div', {
      staticClass: "gap-4 justify-between items-start"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: menu.active,
        expression: "menu.active"
      }],
      attrs: {
        "type": "checkbox",
        "id": `checkbox-${index}`
      },
      domProps: {
        "checked": Array.isArray(menu.active) ? _vm._i(menu.active, null) > -1 : menu.active
      },
      on: {
        "change": function ($event) {
          var $$a = menu.active,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(menu, "active", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(menu, "active", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(menu, "active", $$c);
          }
        }
      }
    }), _c('label', {
      staticClass: "font-medium py-3 px-5 flex gap-4 items-center justify-between cursor-pointer transition-all duration-200 hover:text-primary-500",
      attrs: {
        "for": `checkbox-${index}`
      }
    }, [_c('div', {
      staticClass: "flex gap-4 items-center justify-start"
    }, [_c('span', {
      staticClass: "inline-block flex-shrink-0"
    }, [_c(menu.icon, {
      tag: "component",
      attrs: {
        "width": "24",
        "height": "24"
      }
    })], 1), _c('transition', {
      attrs: {
        "name": "fade",
        "duration": 100
      }
    }, [_c('p', {
      staticClass: "font-medium title whitespace-nowrap"
    }, [_vm._v(_vm._s(menu.title))])])], 1), _c('Chevron', {
      attrs: {
        "color": "white",
        "width": "12",
        "height": "12",
        "direction": menu.active ? 'up' : 'down'
      }
    })], 1), _c('div', {
      staticClass: "submenu",
      class: {
        active: menu.active
      }
    }, _vm._l(menu.submenu, function (submenu, idx) {
      return _c('router-link', {
        key: idx,
        staticClass: "flex px-6 py-2 gap-4 items-center transition-all duration-200 hover:text-primary-500",
        class: {
          'active-link': _vm.activePath === submenu.url
        },
        attrs: {
          "to": submenu.url
        }
      }, [_c('div', {
        staticClass: "font-medium"
      }, [_vm._v(_vm._s(submenu.title))])]);
    }), 1)])], 1);
  }), _c('div', {
    staticClass: "cursor-pointer text-white border border-white text-sm p-2 rounded mx-5 mt-5",
    on: {
      "click": function ($event) {
        return _vm.doLogout();
      }
    }
  }, [_vm._v("Logout")])], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }